// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

/* eslint-disable no-restricted-globals */

import config from '@root/config';

function imageDataToRLE(imageData: Uint8ClampedArray): number[] {
    const rle: number[] = [];

    let prev = 0;
    let summ = 0;
    for (let i = 3; i < imageData.length; i += 4) {
        const alpha = imageData[i] > 0 ? 1 : 0;
        if (prev !== alpha) {
            rle.push(summ);
            prev = alpha;
            summ = 1;
        } else {
            summ++;
        }
    }

    rle.push(summ);
    return rle;
}

function RLE2Mask(rle: number[], width: number, height: number): number[] {
    const decoded = Array(width * height).fill(0);
    const { length } = rle;
    let decodedIdx = 0;
    let value = 0;
    let i = 0;

    while (i < length) {
        let count = rle[i];
        while (count > 0) {
            decoded[decodedIdx] = value;
            decodedIdx++;
            count--;
        }
        i++;
        value = Math.abs(value - 1);
    }

    return decoded;
}

function rotate2DPoints(cx: number, cy: number, angle: number, points: number[]): number[] {
    const rad = (Math.PI / 180) * angle;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const result = [];
    for (let i = 0; i < points.length; i += 2) {
        const x = points[i];
        const y = points[i + 1];
        result.push(
            (x - cx) * cos - (y - cy) * sin + cx,
            (y - cy) * cos + (x - cx) * sin + cy,
        );
    }

    return result;
}

function approxPolyDP(points: number[], threshold: number, closed = true): number[] {
    const { cv } = self as any;
    if (points.length < 3 || points.length % 2) {
        return points;
    }

    const rows = points.length / 2;
    const cols = 2;
    const approx = new cv.Mat();
    const contour = cv.matFromArray(rows, cols, cv.CV_32FC1, points);
    try {
        cv.approxPolyDP(contour, approx, threshold, closed); // approx output type is CV_32F
        return Array.from(approx.data32F);
    } finally {
        approx.delete();
        contour.delete();
    }
}

function findContours(src: any): number[][] {
    const { cv } = self as any;
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    const expanded = new cv.Mat();
    const kernel = cv.Mat.ones(2, 2, cv.CV_8U);
    const anchor = new cv.Point(-1, -1);
    const jsContours: number[][] = [];

    try {
        cv.copyMakeBorder(src, expanded, 1, 1, 1, 1, cv.BORDER_CONSTANT);
        // morpth transform to get better contour including all the pixels
        cv.dilate(
            expanded,
            expanded,
            kernel,
            anchor,
            1,
            cv.BORDER_CONSTANT,
            cv.morphologyDefaultBorderValue(),
        );
        cv.findContours(expanded, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);
        for (let i = 0; i < contours.size(); i++) {
            const contour = contours.get(i);
            // subtract offset we created when copied source image
            const jsContour = Array.from(contour.data32S as number[]).map((el) => el - 1);
            contour.delete();
            jsContours.push(approxPolyDP(jsContour, 1));
        }
    } finally {
        kernel.delete();
        expanded.delete();
        hierarchy.delete();
        contours.delete();
    }

    return jsContours;
}

function getContoursFromRLE(rle: number[]): number[][] {
    const { cv } = self as any;
    const [left, top, right, bottom] = rle.slice(-4);
    const width = right - left + 1;
    const height = bottom - top + 1;
    const data = RLE2Mask(rle.slice(0, -4), width, height);
    const src = cv.matFromArray(height, width, cv.CV_8UC1, data);

    try {
        // avoid incorrect contours, sometimes opencv may predict such for 1-pixel masks
        const contours = findContours(src).filter((contour) => contour.length >= 6);
        if (contours.length) {
            return contours.map((contour) => contour.map((val, idx) => {
                if (idx % 2) {
                    return val + top;
                }
                return val + left;
            }));
        }
        throw new Error('Empty contour received from state');
    } finally {
        src.delete();
    }
}

export enum ConvertMethod {
    RECT_TO_POLYGON = 'rect_to_polygon',
    RECT_TO_MASK = 'rect_to_mask',

    POLYGON_TO_RECT = 'polygon_to_rect',
    POLYGON_TO_MASK = 'polygon_to_mask',

    MASK_TO_RECT = 'mask_to_rect',
    MASK_TO_POLYGON = 'mask_to_polygon',
}

export default class ConverterManager {
    private offscreenCanvas: OffscreenCanvas;
    private context: OffscreenCanvasRenderingContext2D;
    private initialized: boolean;

    public constructor() {
        this.initialized = false;
        this.offscreenCanvas = new OffscreenCanvas(0, 0);
        this.context = this.offscreenCanvas.getContext('2d', { willReadFrequently: true });
    }

    private checkRectangle(rectanglePoints: number[]): void {
        if (
            rectanglePoints.length !== 4 ||
            rectanglePoints[0] > rectanglePoints[2] ||
            rectanglePoints[1] > rectanglePoints[3]
        ) {
            throw new Error('Incorrect rectangle, not enough points or width/height less than 0');
        }
    }

    private checkPolygon(polygonPoints: number[]): void {
        if (polygonPoints.length < 6) {
            throw new Error('Incorrect polygon, number of vertices less than 6');
        }
    }

    private checkMask(rle: number[]): void {
        const [left, top, right, bottom] = rle.slice(-4);
        const width = right - left + 1;
        const height = bottom - top + 1;
        const rleSum = rle.slice(0, -4).reduce((acc, val) => acc + val, 0);

        if (rleSum !== width * height) {
            throw new Error('Incorrect mask, rle sum is not equal to the mask dimension');
        }
    }

    private resizeCanvas(width: number, height: number): void {
        if (this.offscreenCanvas.width !== width || this.offscreenCanvas.height !== height) {
            this.offscreenCanvas.width = width;
            this.offscreenCanvas.height = height;
            this.context = this.offscreenCanvas.getContext('2d', { willReadFrequently: true });
        }
    }

    private getContext(): OffscreenCanvasRenderingContext2D {
        const { context } = this;
        if (context) {
            context.reset();
            return context;
        }

        throw new Error('Offscreen canvas context is null');
    }

    private rectToPolygon(rectPoints: number[], rotation: number | unknown): number[] {
        this.checkRectangle(rectPoints);
        const [xtl, ytl, xbr, ybr] = rectPoints;
        const polygonPoints = [xtl, ytl, xbr, ytl, xbr, ybr, xtl, ybr];
        if (typeof rotation === 'number') {
            const cx = (xtl + xbr) / 2;
            const cy = (ytl + ybr) / 2;
            return rotate2DPoints(cx, cy, rotation, polygonPoints);
        }

        return polygonPoints;
    }

    private rectToMask(rectPoints: number[], rotation: number | unknown, width: number, height: number): number[] {
        const polygonPoints = this.rectToPolygon(rectPoints, rotation);
        return this.polygonToMask(polygonPoints, width, height);
    }

    private polygonToRect(polygonPoints: number[]): number[] {
        this.checkPolygon(polygonPoints);
        let xtl = polygonPoints[0];
        let ytl = polygonPoints[1];
        let xbr = xtl;
        let ybr = ytl;
        polygonPoints.forEach((coordinate: number, index: number) => {
            if (index % 2) {
                ytl = Math.min(ytl, coordinate);
                ybr = Math.max(ybr, coordinate);
            } else {
                xtl = Math.min(xtl, coordinate);
                xbr = Math.max(xbr, coordinate);
            }
        });
        return [xtl, ytl, xbr, ybr];
    }

    private polygonToMask(polygonPoints: number[], width: number, height: number): number[] {
        this.checkPolygon(polygonPoints);
        this.resizeCanvas(width, height);

        const integerPoints = polygonPoints.map((coord) => Math.floor(coord));
        const [xtl, ytl, xbr, ybr] = this.polygonToRect(integerPoints);
        const context = this.getContext();
        context.fillStyle = 'black';
        context.moveTo(integerPoints[0], integerPoints[1]);
        integerPoints.forEach((_, idx: number) => {
            if (idx >= 2 && idx % 2) {
                context.lineTo(integerPoints[idx - 1], integerPoints[idx]);
            }
        });
        context.closePath();
        context.fill();
        const imageData = context.getImageData(xtl, ytl, xbr - xtl + 1, ybr - ytl + 1);
        const rle = imageDataToRLE(imageData.data);
        rle.push(xtl, ytl, xbr, ybr);
        return rle;
    }

    private maskToRect(rle: number[]): number[] {
        this.checkMask(rle);
        const [left, top, right, bottom] = rle.slice(-4);
        return [left, top, right, bottom];
    }

    private maskToPolygon(rle: number[]): number[][] {
        this.checkMask(rle);
        return getContoursFromRLE(rle);
    }

    public convert(
        method: ConvertMethod,
        points: number[],
        rotation: number | unknown,
        clientID: number,
        width: number,
        height: number,
    ): number[][] {
        if (!this.initialized) {
            throw new Error('Manager was not initialized');
        }

        let sourceShapeType: string | null = null;
        let targetShapeType: string | null = null;
        try {
            switch (method) {
                case ConvertMethod.RECT_TO_POLYGON:
                    sourceShapeType = 'rectangle';
                    targetShapeType = 'polygon';
                    return [this.rectToPolygon(points, rotation)];
                case ConvertMethod.RECT_TO_MASK:
                    sourceShapeType = 'rectangle';
                    targetShapeType = 'mask';
                    return [this.rectToMask(points, rotation, width, height)];
                case ConvertMethod.POLYGON_TO_RECT:
                    sourceShapeType = 'polygon';
                    targetShapeType = 'rectangle';
                    return [this.polygonToRect(points)];
                case ConvertMethod.POLYGON_TO_MASK:
                    sourceShapeType = 'polygon';
                    targetShapeType = 'mask';
                    return [this.polygonToMask(points, width, height)];
                case ConvertMethod.MASK_TO_RECT:
                    sourceShapeType = 'mask';
                    targetShapeType = 'rectangle';
                    return [this.maskToRect(points)];
                case ConvertMethod.MASK_TO_POLYGON:
                    sourceShapeType = 'mask';
                    targetShapeType = 'polygon';
                    return this.maskToPolygon(points);
                default:
                    throw new Error(`Conversion method "${method}" not implemented`);
            }
        } catch (error: unknown) {
            const basicMessage = `Error occurred converting shape ID "${clientID}"` +
                    ` from "${sourceShapeType}" to "${targetShapeType}". `;
            if (error instanceof Error) {
                throw new Error(`${basicMessage}${error.message}`);
            }
            throw new Error(basicMessage);
        }
    }

    public initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            (self as any).Module = {
                onRuntimeInitialized: () => {
                    this.initialized = true;
                    resolve();
                },
            };

            try {
                (self as any).importScripts(config.OPENCV_PATH);
            } catch (error: unknown) {
                reject(error);
            }
        });
    }

    public get isInitialized(): boolean {
        return this.initialized;
    }
}

export enum WorkerAction {
    INITIALIZE = 'initialize',
    RUN = 'run',
}

export interface WorkerBody {
    command: WorkerAction;
    method?: ConvertMethod;
    shapes?: any[];
    width?: number;
    height?: number;
}

export type WorkerResponse = {
    shapes?: any[];
    error?: string;
    warnings?: string[];
};

if ((self as any).importScripts) {
    const manager = new ConverterManager();
    onmessage = (event: any) => {
        const errorToMessage = (error: unknown): string => {
            if (error instanceof Error) {
                return error.message;
            }
            if (typeof error === 'string') {
                return error;
            }

            console.error(error);
            return 'Unknown error, please check console';
        };

        const { command } = event.data;
        if (command === WorkerAction.INITIALIZE) {
            manager.initialize().then(() => {
                postMessage({});
            }).catch((error: unknown) => {
                postMessage({
                    error: `Could not initialize OpenCV in a WebWorker. ${errorToMessage(error)}`,
                });
            });
        } else if (command === WorkerAction.RUN) {
            const {
                method, shapes, width, height,
            } = event.data as WorkerBody;

            let shapeTo: 'rectangle' | 'polygon' | 'mask' = 'rectangle';
            switch (method) {
                case ConvertMethod.MASK_TO_POLYGON:
                case ConvertMethod.RECT_TO_POLYGON: {
                    shapeTo = 'polygon';
                    break;
                }
                case ConvertMethod.POLYGON_TO_RECT:
                case ConvertMethod.MASK_TO_RECT: {
                    shapeTo = 'rectangle';
                    break;
                }
                case ConvertMethod.POLYGON_TO_MASK:
                case ConvertMethod.RECT_TO_MASK: {
                    shapeTo = 'mask';
                    break;
                }
                default: {
                    postMessage({ error: `Conversion method "${method}" not implemented` });
                }
            }

            const warnings = [];
            const convertedShapes = shapes.map((shape) => {
                try {
                    const contours = manager.convert(
                        method,
                        shape.points,
                        shape.rotation,
                        shape.clientID,
                        width,
                        height,
                    );

                    return contours.map((contour) => ({ ...shape, type: shapeTo, points: contour }));
                } catch (error: unknown) {
                    warnings.push(
                        `Error occurred converting object ${JSON.stringify(shape)}. ${errorToMessage(error)}`,
                    );
                    return shape;
                }
            }).flat();
            postMessage({ shapes: convertedShapes, warnings });
        } else {
            postMessage({ error: `Not supported command received "${command}"` });
        }
    };
}
